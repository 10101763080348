<template>
  <a-layout>
    <a-layout-sider style="background: #fff;">
      <menuNav></menuNav>
    </a-layout-sider>
    <a-layout>
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import menuNav from './moudel/menuNav'
export default {
  components: {
    menuNav: menuNav
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
  .ant-layout {
    height: 100%;
  }
</style>
