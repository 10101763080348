import examplesRouter from '@/router/examples'
export default {
  data() {
    return {
      openKeys: []
    }
  },
  watch: {
    $route: function() {
      this.updateMenu()
    },
    openKeys(val) {
      console.log('openKeys', val)
    }
  },
  mounted() {
    this.updateMenu()
  },
  methods: {
    updateMenu() {
      this.openKeys = [this.$route.name]
    },
    handleClick(e) {
      console.log('click', e)
    },
    titleClick(e) {
      console.log('titleClick', e)
    },
    getMenuItem(data, menuNav) {
      data.forEach(item => {
        const title = (item.meta && item.meta.title) || ''
        const to = {
          name: item.name
        }
        if (item.children) {
          const childerArr = []
          this.getMenuItem(item.children, childerArr)
          menuNav.push(<a-sub-menu key={item.name}>
            <span slot='title'><span>{title}</span></span>
            {childerArr}
          </a-sub-menu>)
        } else {
          menuNav.push(<a-menu-item key={item.name}><router-link to={ to }>{title}</router-link></a-menu-item>)
        }
      })
    }
  },
  render() {
    const menuNav = []
    this.getMenuItem(examplesRouter.children, menuNav)
    console.log(menuNav)
    return (
      <a-menu
        style='width: 100%'
        vModel={this.openKeys}
        mode='inline'
        onClick={this.handleClick}
      >
        {/* <a-menu-item key='/examples/report'>report</a-menu-item>*/}
        {menuNav}
      </a-menu>
    )
  }
}
